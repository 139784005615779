import { FC } from 'react';
import { ParagraphSectionFragment } from '../../../generated/types';
import { Box, Container } from '@chakra-ui/react';
import { ParagraphRenderer } from '../ParagraphRenderer';
import { HTMLText } from '../../Common/HTMLText';
import { Divider } from '../../Common/Divider';

interface SectionProps {
  data: ParagraphSectionFragment;
}

export const Section: FC<SectionProps> = ({ data }) => {
  return (
    <Box bg={data.backgroundColor || 'white'}>
      {data.sectionText && (
        <Container maxW="container.main" pt={6}>
          <HTMLText text={data.sectionText} />

          {data.divider && <Divider my={3} dividerType={data.divider} />}
        </Container>
      )}

      <ParagraphRenderer
        paragraphs={data.content}
        wrapperProps={{
          py: 6,
        }}
        paragraphWrappers={{
          _all: 'container',
          ParagraphHero: 'none',
        }}
      />
    </Box>
  );
};
