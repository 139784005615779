import { FC } from 'react';
import { Box, BoxProps, Icon } from '@chakra-ui/react';
import { HorseIcon } from '../../theme/Icons/HorseIcon';

type DividerProps = BoxProps & {
  dividerType?: string;
  side?: string;
};

export const Divider: FC<DividerProps> = ({
  dividerType = 'default',
  side,
  ...props
}) => {
  return (
    <Box
      mr="auto"
      marginInlineStart={side === 'right' ? 'auto' : 0}
      className="divider"
      layerStyle={`divider_${dividerType}`}
      pos="relative"
      {...props}
    >
      {(dividerType === 'blue_horse' || dividerType === 'white_horse') && (
        <Box
          pos="absolute"
          left="50%"
          top="50%"
          transform="translate(-50%, -50%)"
        >
          <Icon
            fontSize="3xl"
            as={HorseIcon}
            color={dividerType === 'blue_horse' ? 'primary' : 'white'}
          />
        </Box>
      )}
    </Box>
  );
};
